import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'gatsby-theme-kn/src/components/modules/Layout'

import { useTranslation } from 'react-i18next'
import Link from 'gatsby-theme-kn/src/components/atoms/Link'

const NotFoundPage = ({ block, name, location }) => {
  const { t } = useTranslation()
  const [lang, setLang] = useState('de')

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'de') {
      let currentLang = location.pathname.split('/')[1]
      setLang(currentLang)
    }
  }, [location.pathname])

  return (
    <Layout locale={lang}>
      <Helmet>
        <title>404 - Seite nicht gefunden | König + Neurath</title>
      </Helmet>
      <section className="skewed-block">
        <div className="skewed-block-space"></div>
      </section>

      <section className="spa-block">
        <div className="spa-block-container">
          <div className="spa-block-wrapper">
            <div className="spa-block-row">
              <div className="spa-block-header text-center">
                <h1>{t('404_page.title')}</h1>
                <div className="h1-subheadline">{t('404_page.text')}</div>
                <Link className="mt-2 btn btn-primary" link={`/${lang}`}>
                  {t('404_page.back')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
